exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-ru-about-index-tsx": () => import("./../../../src/pages/ru/about/index.tsx" /* webpackChunkName: "component---src-pages-ru-about-index-tsx" */),
  "component---src-pages-ru-cases-index-tsx": () => import("./../../../src/pages/ru/cases/index.tsx" /* webpackChunkName: "component---src-pages-ru-cases-index-tsx" */),
  "component---src-pages-ru-index-tsx": () => import("./../../../src/pages/ru/index.tsx" /* webpackChunkName: "component---src-pages-ru-index-tsx" */),
  "component---src-pages-ru-privacy-tsx": () => import("./../../../src/pages/ru/privacy.tsx" /* webpackChunkName: "component---src-pages-ru-privacy-tsx" */),
  "component---src-pages-ru-services-index-tsx": () => import("./../../../src/pages/ru/services/index.tsx" /* webpackChunkName: "component---src-pages-ru-services-index-tsx" */),
  "component---src-pages-ru-tech-index-tsx": () => import("./../../../src/pages/ru/tech/index.tsx" /* webpackChunkName: "component---src-pages-ru-tech-index-tsx" */),
  "component---src-templates-case-page-template-tsx": () => import("./../../../src/templates/casePageTemplate.tsx" /* webpackChunkName: "component---src-templates-case-page-template-tsx" */),
  "component---src-templates-service-page-template-tsx": () => import("./../../../src/templates/servicePageTemplate.tsx" /* webpackChunkName: "component---src-templates-service-page-template-tsx" */),
  "component---src-templates-tech-page-template-tsx": () => import("./../../../src/templates/techPageTemplate.tsx" /* webpackChunkName: "component---src-templates-tech-page-template-tsx" */)
}

