import React from 'react'
import { useSystem } from '@stage-ui/system'
import { Link as GatsbyLink } from 'gatsby'

export type LinkProps = Stage.AllProps<HTMLAnchorElement> & {
	children: React.ReactNode
	to: string
	target?: '_blank' | '_parent' | '_self' | '_top'
	replace?: boolean
}
type LinkClasses = { container: void }

const createClasses: Stage.CreateClasses<LinkClasses, LinkProps> = (theme) => ({
	container: { all: 'unset', cursor: 'pointer', color: theme.color.primary.hex() },
})

export const Link = (props: LinkProps) => {
	const { to, onClick, replace, target, children } = props
	const { classes, attributes, events, styleProps } = useSystem(
		'Link',
		props,
		createClasses,
		{ focus: 'tabOnly' },
	)

	return (
		<GatsbyLink
			to={to}
			replace={replace}
			target={target}
			onClick={onClick}
			css={[classes.container, styleProps.all]}
			{...attributes}
			{...events}
		>
			{children as string}
		</GatsbyLink>
	)
}
