import { PageBlock } from '@src/components/PageBlock'
import { MenuInline } from '@src/layout/Menu/MenuInline'
import { Block, Flexbox, Link, Text } from '@stage-ui/core'
import React from 'react'

export const Footer = () => (
	<footer>
		<Block
			w="100%"
			mb="2rem"
			style={(t) => ({
				borderTop: `1px solid ${t.color.gray[600].alpha(0.1).string()}`,
			})}
		/>
		<PageBlock direction={['row', 'row', 'column']}>
			<MenuInline
				alignSelf="flex-start"
				style={[
					{
						'> a > span': {
							fontWeight: '400',
						},
						gap: '2rem',
					},
					'*',
					'*',
					{
						gap: '1rem',
					},
				]}
			/>
			<Flexbox column pl={['m', '*', 0]} mb="2rem" mt={['*', '*', '2rem']}>
				<Text
					color={(c) => c.black.alpha(0.75)}
					size=".875rem"
					lineHeight="1.125rem"
					weight={300}
					mb=".5rem"
				>
					Copyright Script Heads ©
				</Text>
				<Link
					color={(c) => c.black.alpha(1)}
					href="/ru/privacy"
					size=".875rem"
					weight={300}
				>
					Политика конфиденциальности
				</Link>
			</Flexbox>
		</PageBlock>
	</footer>
)
