import LogoFull from '@assets/svg/logo-full.fc.svg'
import { SVG } from '@components/SVG'
import { Link } from '@components/Link'
import React from 'react'
import { PageBlock } from '@src/components/PageBlock'
import { Menu } from './Menu'

export const Head = () => (
	<header>
		<PageBlock centered direction="row" h="6rem">
			<Link to="/" mr="1.25rem">
				<SVG from={LogoFull} h="2.75rem" w="auto" />
			</Link>
			<Menu />
		</PageBlock>
	</header>
)
