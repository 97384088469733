import { useSanityMenu } from '@src/graphql/useSanityMenu'
import { Flexbox, Link, useTheme } from '@stage-ui/core'
import FlexboxTypes from '@stage-ui/core/layout/Flexbox/types'
import { ArrowRightUp } from '@stage-ui/icons'
import React from 'react'
import { useLocation } from '@reach/router'
import { useSanityMeta } from '@src/graphql/useSanityMeta'
import { SVG } from '@src/components/SVG'
import TelegramIcon from '@assets/svg/telegram-mobile.fc.svg'
import { MenuItem } from './MenuItem'

export type MenuMobileProps = {
	setOpen: (state: boolean) => void
	isOpen: boolean
} & FlexboxTypes.Props

export const MenuMobile = (props: MenuMobileProps) => {
	const { setOpen, isOpen, ...restProps } = props
	const { color } = useTheme()
	const location = useLocation()
	const items = useSanityMenu()
	const meta = useSanityMeta()

	return (
		<Flexbox
			justifyContent="space-between"
			backgroundColor={color.white.alpha(0.9)}
			alignItems="auto"
			flex={1}
			t="0"
			l="0"
			r="0"
			b="0"
			p="2rem"
			position="fixed"
			style={{
				backdropFilter: 'blur(4px)',
				opacity: isOpen ? 1 : 0,
				visibility: isOpen ? 'visible' : 'hidden',
				transform: isOpen ? 'translateY(0)' : 'translateY(-0.5rem)',
				transition: 'all 0.25s',
				zIndex: 100,
			}}
			flexGrow={1}
			display={['none', 'none', 'flex']}
			column
			{...restProps}
		>
			<Flexbox
				column
				mt="2rem"
				style={{
					maxWidth: '30rem',
					gap: '0.5rem',
				}}
			>
				{items.map((item) => (
					<MenuItem
						key={item.id}
						large
						active={!!location.pathname.match(item.url)}
						href={item.url}
						isExternal={item.isExternal}
						onClick={() => {
							setOpen(false)
						}}
					>
						{item.title}
						{item.isExternal && (
							<ArrowRightUp
								position="absolute"
								style={{
									top: '0.2rem',
									right: '-2rem',
								}}
								size="2rem"
							/>
						)}
					</MenuItem>
				))}
			</Flexbox>
			<Flexbox my="1rem">
				<Link href={meta.contact_telegram} target="_blank" mb="1rem">
					<SVG from={TelegramIcon} w="3rem" h="3rem" />
				</Link>
				<Flexbox column ml="m">
					<Link
						href={`tel:+${meta.contact_phone.replace(/\D+/g, '')}`}
						color="black"
						weight={600}
						mb=".5rem"
					>
						{meta.contact_phone}
					</Link>
					<Link
						href={`mailto:${meta.contact_email}`}
						color="black"
						weight={300}
						mb=".5rem"
					>
						{meta.contact_email}
					</Link>
				</Flexbox>
			</Flexbox>
		</Flexbox>
	)
}
