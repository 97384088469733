import { lightTheme as defaultLightTheme } from '@stage-ui/core'

import GothamPro from '@assets/fonts/GothamPro.ttf'
import GothamProLight from '@assets/fonts/GothamPro-Light.ttf'
import GothamProBold from '@assets/fonts/GothamPro-Bold.ttf'
import GothamProMedium from '@assets/fonts/GothamPro-Medium.ttf'
import GothamProBlack from '@assets/fonts/GothamPro-Black.ttf'
import GothamNarrowBook from '@assets/fonts/GothamNarrow-Book.ttf'

export const globalStyles = [
	{
		'@font-face': {
			fontFamily: 'GothamPro',
			fontWeight: 300,
			fontStyle: 'normal',
			src: `url("${GothamProLight}") format("truetype")`,
		},
	},
	{
		'@font-face': {
			fontFamily: 'GothamPro',
			fontWeight: 500,
			fontStyle: 'normal',
			src: `url("${GothamPro}") format("truetype")`,
		},
	},
	{
		'@font-face': {
			fontFamily: 'GothamPro',
			fontWeight: 600,
			fontStyle: 'normal',
			src: `url("${GothamProMedium}") format("truetype")`,
		},
	},
	{
		'@font-face': {
			fontFamily: 'GothamPro',
			fontWeight: 700,
			fontStyle: 'normal',
			src: `url("${GothamProBold}") format("truetype")`,
		},
	},
	{
		'@font-face': {
			fontFamily: 'GothamPro',
			fontWeight: 900,
			fontStyle: 'normal',
			src: `url("${GothamProBlack}") format("truetype")`,
		},
	},
	{
		'@font-face': {
			fontFamily: 'GothamNarrow',
			fontWeight: 500,
			fontStyle: 'normal',
			src: `url("${GothamNarrowBook}") format("truetype")`,
		},
	},
	{
		'html, body': {
			fontFamily: 'GothamPro, sans-serif, serif',
			fontSize: '16px',
			'@media (max-width: 375px)': {
				fontSize: '14px',
			},
			'@media (max-width: 320px)': {
				fontSize: '12px',
			},
			'@media (max-width: 300px)': {
				fontSize: '10px',
			},
			'@media (max-width: 280px)': {
				fontSize: '8px',
			},
		},
		'::-webkit-scrollbar': {
			display: 'none',
		},
		'ul, ol': {
			paddingLeft: '2rem',
			fontSize: '1.125rem',
			lineHeight: 1.6,
			paddingBottom: '2rem',
		},
		li: {
			fontSize: '1.25rem',
			lineHeight: 1.6,
			paddingBottom: '1rem',
		},
		ul: {
			'li::marker': {
				fontSize: '150%',
			},
		},
		p: {
			fontSize: '1.25rem',
			lineHeight: 1.6,
			paddingBottom: '2rem',
			img: {
				maxWidth: '100%',
				borderRadius: '1rem',
			},
			video: {
				maxWidth: '100%',
				borderRadius: '1rem',
			},
		},
		'p, h1, h2, h3, h4, h5, ul, ul, li': {
			maxWidth: '48rem',
		},
	},
]

export const lightTheme = defaultLightTheme.replace({
	main: {
		color: {
			primary: 'rgb(30, 120, 230)',
		},
		breakpoints: ['150rem', '80rem', '64rem', '48rem', '40rem', '20rem'],
	},
	assets: {
		typography: {
			header: {
				xl: {
					fontSize: '3rem',
					fontWeight: '900',
					lineHeight: 1.2,
					margin: 0,
				},
				l: {
					fontSize: '3rem',
					fontWeight: '800',
					lineHeight: 1.4,
					paddingBottom: '1rem',
					margin: 0,
				},
				m: {
					fontWeight: '700',
					fontSize: '2rem',
					lineHeight: 1.4,
					paddingBottom: '0.75rem',
					margin: 0,
				},
				s: {
					fontWeight: '700',
					fontSize: '1.5rem',
					lineHeight: 1.3,
					paddingBottom: '0.75rem',
					margin: 0,
				},
				xs: {
					fontWeight: '700',
					fontSize: '1.25rem',
					lineHeight: 1.2,
					paddingBottom: '0.5rem',
					margin: 0,
				},
			},
			paragraph: {
				xl: {
					whiteSpace: 'break-spaces',
					fontSize: '1.5rem',
					lineHeight: 1.6,
				},
				l: {
					whiteSpace: 'break-spaces',
					fontSize: '1.375rem',
					lineHeight: 1.6,
				},
				m: {
					whiteSpace: 'break-spaces',
					fontSize: '1.25rem',
					lineHeight: 1.6,
					paddingBottom: '2rem',
				},
				s: {
					whiteSpace: 'break-spaces',
					fontSize: '1rem',
					lineHeight: 1.6,
				},
				xs: {
					fontSize: '0.875rem',
					lineHeight: 1.6,
				},
			},
		},
	},
	overrides: {
		Button: () => ({
			container: () => ({
				borderRadius: 0,
				padding: '2rem 3rem',
				fontWeight: 600,
			}),
		}),
		Field: () => ({
			label: {
				color: '#000',
				fontWeight: '600',
			},
			field: {
				padding: '1rem',
				borderRadius: 0,
				boxShadow: 'none !important',
				background: 'rgba(255,255,255,0.8)',
			},
		}),
	},
})
