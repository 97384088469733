import { graphql, useStaticQuery } from 'gatsby'

export type MenuItemData = {
	id: string
	title: string
	url: string
	isExternal: boolean
}

export const useSanityMenu = () => {
	const data = useStaticQuery<{ allSanityMenu: Queries.SanityMenuConnection }>(graphql`
		{
			allSanityMenu(sort: { weight: ASC }) {
				nodes {
					_id
					name
					slug {
						current
					}
				}
			}
		}
	`)
	return data.allSanityMenu.nodes.map((item) => {
		const result: MenuItemData = {
			id: item._id!,
			title: item.name!,
			url: item.slug!.current!,
			isExternal: item.slug!.current!.startsWith('http'),
		}
		return result
	})
}
