/* eslint-disable */
import React, { useEffect } from 'react'
//@ts-expect-error
import SimplexNoise from './noice.js'
import BlockTypes from '@stage-ui/core/layout/Block/types.js';

const { PI, cos, sin, abs, random } = Math;
const TAU = 2 * PI;
const rand = (n: number) => n * random();
const randRange = (n: number) => n - rand(2 * n);
const fadeInOut = (t: number, m: number) => {
  let hm = 0.5 * m;
  return abs((t + hm) % m - hm) / (hm);
};
const lerp = (n1: number, n2: number, speed: number) => (1 - speed) * n1 + speed * n2;

const particleCount = 200;
const particlePropCount = 10;
const particlePropsLength = particleCount * particlePropCount;
const rangeY = 500;
const baseTTL = 200;
const rangeTTL = 150;
const baseSpeed = 0.15;
const rangeSpeed = 1;
const baseRadius = 1;
const rangeRadius = 14;
const baseHue = 200;
const rangeHue = 30;
const noiseSteps = 5;
const xOff = 0.00125;
const yOff = 0.00125;
const zOff = 0.0005;

const backgroundColor = '#ffffff';

let canvas: any
let ctx: any
let center: number[];
let tick: number;
let simplex: { noise3D: (arg0: number, arg1: number, arg2: number) => number; };
let particleProps: Float32Array;

export const FancyBackground = (props: { styles: React.CSSProperties}) => {
  const { styles } = props
  useEffect(() => {
    let running = true
    function setup() {
      canvas = document.querySelector('#fancy_background')
      ctx = canvas.getContext('2d')

      const ratio = window.devicePixelRatio || 1
      canvas.width = ratio * (canvas.parentElement?.offsetWidth || 100)
      canvas.height = ratio * (canvas.parentElement?.offsetHeight || 50)

      center = [];

      resize();
      initParticles();
      draw();
    }

    function initParticles() {
      tick = 0;
      simplex = new SimplexNoise();
      particleProps = new Float32Array(particlePropsLength);

      let i;

      for (i = 0; i < particlePropsLength; i += particlePropCount) {
        initParticle(i);
      }
    }

    function initParticle(i: number) {
      let x, y, vx, vy, life, ttl, speed, radius, hue;

      x = rand(canvas.width);
      y = center[1] + randRange(rangeY);
      vx = 0;
      vy = 0;
      life = 0;
      ttl = baseTTL + rand(rangeTTL);
      speed = baseSpeed + rand(rangeSpeed);
      radius = baseRadius + rand(rangeRadius);
      hue = baseHue + rand(rangeHue);

      particleProps.set([x, y, vx, vy, life, ttl, speed, radius, hue], i);
    }

    function drawParticles() {
      let i;

      for (i = 0; i < particlePropsLength; i += particlePropCount) {
        updateParticle(i);
      }
    }

    function updateParticle(i: number) {
      let i2 = 1 + i, i3 = 2 + i, i4 = 3 + i, i5 = 4 + i, i6 = 5 + i, i7 = 6 + i, i8 = 7 + i, i9 = 8 + i;
      let n, x, y, vx, vy, life, ttl, speed, x2, y2, radius, hue;

      x = particleProps[i];
      y = particleProps[i2];
      n = simplex.noise3D(x * xOff, y * yOff, tick * zOff) * noiseSteps * TAU;
      vx = lerp(particleProps[i3], cos(n), 0.5);
      vy = lerp(particleProps[i4], sin(n), 0.5);
      life = particleProps[i5];
      ttl = particleProps[i6];
      speed = particleProps[i7];
      x2 = x + vx * speed;
      y2 = y + vy * speed;
      radius = particleProps[i8];
      hue = particleProps[i9];

      drawParticle(x, y, x2, y2, life, ttl, radius, hue);

      life++;

      particleProps[i] = x2;
      particleProps[i2] = y2;
      particleProps[i3] = vx;
      particleProps[i4] = vy;
      particleProps[i5] = life;

      (checkBounds(x, y) || life > ttl) && initParticle(i);
    }

    function drawParticle(x: any, y: any, x2: any, y2: any, life: any, ttl: any, radius: any, hue: any) {
      ctx.save();
      ctx.lineCap = 'round';
      ctx.lineWidth = radius;
      ctx.strokeStyle = `hsla(${hue},100%,80%,${fadeInOut(life, ttl)})`;
      ctx.beginPath();
      ctx.moveTo(x, y);
      ctx.lineTo(x2, y2);
      ctx.stroke()
      ctx.closePath();
      ctx.restore();
    }

    function checkBounds(x: number, y: number) {
      return (
        x > canvas.width ||
        x < 0 ||
        y > canvas.height ||
        y < 0
      );
    }

    function resize() {
      const { innerWidth, innerHeight } = window;

      const ratio = window.devicePixelRatio || 1
      canvas.width = ratio * (innerWidth || 100)
      canvas.height = ratio * (innerHeight || 50)

      ctx.drawImage(canvas, 0, 0);

      center[0] = 0.5 * canvas.width;
      center[1] = 0.5 * canvas.height;
    }

    function draw() {
      if (!running) {
        return
      }
      tick++;
      ctx.fillStyle = backgroundColor;
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      drawParticles();
      window.requestAnimationFrame(draw);
    }

    window.addEventListener('resize', resize);

    setup()

    return () => {
      running = false
      window.removeEventListener('resize', resize);
    }
  }, [])

  return (
    <canvas id="fancy_background" css={{ width: "100%", height: "100%", ...styles }} />
  )

}