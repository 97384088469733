import React from 'react'
import { Flexbox } from '@stage-ui/core'
import FlexboxTypes from '@stage-ui/core/layout/Flexbox/types'

type Props = FlexboxTypes.Props

export const PageBlock = (props: Props) => {
	const { children, direction = 'column', ...restProps } = props
	return (
		<Flexbox justifyContent="center" flex={1}>
			<Flexbox
				w={['78rem', '100%']}
				px={['3rem', '*', '1.5rem']}
				direction={direction}
				{...restProps}
			>
				{children}
			</Flexbox>
		</Flexbox>
	)
}
