import React from 'react'
import { GatsbyBrowser } from 'gatsby'
import { Head } from '@src/layout/Head'
import { FancyBackground } from './FancyBackground'
import { Footer } from './Footer'

export const Layout: GatsbyBrowser['wrapPageElement'] = ({ element, props }) => {
	const isMainPage = props.location?.pathname.split('/').filter((x) => !!x).length < 2

	return (
		<>
			<FancyBackground
				styles={{
					position: 'fixed',
					width: '100vw',
					height: '100vh',
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					transition: 'opacity 1s',
					opacity: isMainPage ? 1 : 0.4,
				}}
			/>
			<Head />
			<main>{element}</main>
			<Footer />
		</>
	)
}
