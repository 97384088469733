import React from 'react'
import { Flexbox, Text } from '@stage-ui/core'
import FlexboxTypes from '@stage-ui/core/layout/Flexbox/types'
import { Link } from 'gatsby-link'

export type MenuItemProps = {
	large?: boolean
	href: string
	leftChild?: React.ReactNode
	active?: boolean
	isExternal?: boolean
} & Omit<FlexboxTypes.Props, 'decoration' | 'overrides'>

export const MenuItem = (props: MenuItemProps) => {
	const {
		large = false,
		href,
		leftChild,
		children,
		active,
		isExternal,
		...flexboxProps
	} = props

	const content = (
		<Text
			display="inline-block"
			position="relative"
			overrides={(theme) => ({
				container: [
					{
						cursor: 'pointer',
						fontWeight: large ? 500 : 600,
						lineHeight: large ? '2.5rem' : 'unset',
						fontSize: large ? '1.5rem' : '1rem',
						color: theme.color.black.hex(),
						':hover': {
							cursor: active ? 'default' : 'pointer',
							color: active
								? theme.color.primary.hex()
								: theme.color.black.alpha(0.8).string(),
						},
					},
					active && { color: theme.color.primary.hex() },
				],
			})}
			{...(!leftChild && flexboxProps)}
		>
			{children}
		</Text>
	)

	return (
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-expect-error
		<Link
			to={href}
			style={{
				textDecoration: 'none',
			}}
			target={isExternal ? '_blank' : '_self'}
		>
			{leftChild && (
				<Flexbox alignItems="center" {...flexboxProps}>
					<>
						{leftChild}
						{content}
					</>
				</Flexbox>
			)}
			{!leftChild && content}
		</Link>
	)
}
