import { graphql, useStaticQuery } from 'gatsby'

export const useSanityMeta = () => {
	const data = useStaticQuery<{ allSanityMeta: Queries.SanityMetaConnection }>(graphql`
		{
			allSanityMeta {
				nodes {
					_id
					key
					value
				}
			}
		}
	`)
	const keyValueObject: Record<string, string> = {}
	data.allSanityMeta.nodes.forEach((item) => {
		if (item.key && item.value) {
			keyValueObject[item.key] = item.value
		}
	})
	return keyValueObject
}
