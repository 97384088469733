import React from 'react'
import { useSystem, breakpointProp, colorProp } from '@stage-ui/system'
import { BreakpointProp } from '@stage-ui/system/props/breakpoint'

type SVGClasses = { container: void }

export type SVGProps = {
	fill?: BreakpointProp<Stage.ColorProp>
	from?: any
	children?: React.SVGProps<SVGSVGElement>['children']
	viewBox?: string
} & Stage.AllProps<HTMLElement, SVGClasses>

const createClasses: Stage.CreateClasses<SVGClasses, SVGProps> = (theme, props) => ({
	container: [
		{ flexShrink: 0 },
		breakpointProp(props.fill, theme, (cv) => ({
			fill: colorProp(cv, theme)?.hex(),
			'*': {
				fill: colorProp(cv, theme)?.hex(),
			},
		})),
	],
})

export const SVG = (props: SVGProps) => {
	const { from: SVGComponent, children, viewBox } = props
	const { attributes, events, classes, styleProps } = useSystem(
		'SVG',
		props,
		createClasses,
	)

	if (SVGComponent) {
		return (
			<SVGComponent
				viewBox={viewBox}
				css={[classes.container, styleProps.all]}
				{...attributes}
				{...events}
			/>
		)
	}

	if (children) {
		return (
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			// TODO: Add SVG support to StageUI
			<svg
				css={[classes.container, styleProps.all]}
				{...attributes}
				{...events}
				viewBox={viewBox}
			>
				{children}
			</svg>
		)
	}

	return null
}
