import { Flexbox } from '@stage-ui/core'
import FlexboxTypes from '@stage-ui/core/layout/Flexbox/types'
import React from 'react'
import { ArrowRightUp } from '@stage-ui/icons'
import { useLocation } from '@reach/router'
import { useSanityMenu } from '@src/graphql/useSanityMenu'
import { MenuItem } from './MenuItem'

export type MenuInlineProps = FlexboxTypes.Props

export const MenuInline = (props: MenuInlineProps) => {
	const { ...flexboxProps } = props
	const location = useLocation()
	const items = useSanityMenu()

	return (
		<Flexbox
			flex={1}
			direction={['row', 'row', 'row', 'column']}
			style={[
				'*',
				'*',
				'*',
				{
					gap: '1rem',
				},
			]}
			{...flexboxProps}
		>
			{items.map((item) => (
				<MenuItem
					flexShrink={0}
					key={item.id}
					active={!!location.pathname.match(item.url)}
					href={item.url}
					isExternal={item.isExternal}
				>
					{item.title}
					{item.isExternal && (
						<ArrowRightUp
							position="absolute"
							style={{
								top: '-0.15rem',
								right: '-1.2rem',
							}}
							size="1.25rem"
						/>
					)}
				</MenuItem>
			))}
		</Flexbox>
	)
}
