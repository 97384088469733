import CloseIcon from '@assets/svg/close.fc.svg'
import MenuIcon from '@assets/svg/menu.fc.svg'
import TelegramIcon from '@assets/svg/telegram.fc.svg'
import { SVG } from '@components/SVG'
import { useSanityMeta } from '@src/graphql/useSanityMeta'
import { MenuInline } from '@src/layout/Menu/MenuInline'
import { MenuMobile } from '@src/layout/Menu/MenuMobile'
import { Flexbox, Link } from '@stage-ui/core'
import React, { useState } from 'react'

export const Menu = () => {
	const [isMobileMenuOpen, setMobileMenuOpen] = useState(false)
	const meta = useSanityMeta()

	const setOpen = (nextState: boolean) => {
		setMobileMenuOpen(nextState)
		if (nextState) {
			document.body.style.overflow = 'hidden'
			return
		}
		document.body.style.overflow = 'auto'
	}

	return (
		<>
			<Flexbox display={['none', 'none', 'flex']} flex={1} />
			<SVG
				from={isMobileMenuOpen ? CloseIcon : MenuIcon}
				p="m"
				w="1.5rem"
				h="1.5rem"
				onClick={() => setOpen(!isMobileMenuOpen)}
				display={['none', 'none', 'flex']}
				style={{ zIndex: 101 }}
			/>
			<MenuInline
				display={['flex', 'flex', 'none']}
				ml="1rem"
				style={{
					gap: '2rem',
				}}
			/>
			<MenuMobile isOpen={isMobileMenuOpen} setOpen={setOpen} />
			<Flexbox alignItems="center" display={['flex', 'flex', 'none']}>
				<Link
					href={`tel:+${meta.contact_phone.replace(/\D+/g, '')}`}
					target="_blank"
					color="black"
					weight={200}
				>
					{meta.contact_phone}
				</Link>
				<Link href={meta.contact_telegram} target="_blank">
					<SVG from={TelegramIcon} mb="-0.125rem" pl="m" w="1.5rem" h="1.5rem" />
				</Link>
			</Flexbox>
		</>
	)
}
