import React from 'react'
import { GatsbyBrowser } from 'gatsby'
import { Viewport } from '@stage-ui/core'
import { Layout } from './src/layout'
import { globalStyles, lightTheme } from './src/theme'

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = Layout
export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => (
	<Viewport theme={lightTheme} global={globalStyles}>
		{element}
	</Viewport>
)
